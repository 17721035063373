export let emotionList =  [
    {
      emotion: "默认",
      // icon: require("@/assets/img/dubbingPage/default.png"),
      emotionKey: "neutral",
    },
    {
      emotion: "愉快",
      // icon: require("@/assets/img/dubbingPage/happy.png"),
      emotionKey: "happy",
    },
    {
      emotion: "生气",
      // icon: require("@/assets/img/dubbingPage/angry.png"),
      emotionKey: "angry",
    },
    {
      emotion: "悲伤",
      // icon: require("@/assets/img/dubbingPage/sad.png"),
      emotionKey: "sad",
    },
    {
      emotion: "沮丧",
      // icon: require("@/assets/img/dubbingPage/frustrated.png"),
      emotionKey: "frustrated",
    },
    {
      emotion: "恐惧",
      // icon: require("@/assets/img/dubbingPage/fear.png"),
      emotionKey: "fear",
    },
    {
      emotion: "严肃",
      // icon: require("@/assets/img/dubbingPage/serious.png"),
      emotionKey: "serious",
    },
    // {
    //   emotion: "抱怨",
    //   icon: require("@/assets/img/dubbingPage/complain.png"),
    //   emotionKey: "happy",
    // },
    // {
    //   emotion: "轻松",
    //   icon: require("@/assets/img/dubbingPage/relaxed.png"),
    //   emotionKey: "happy",
    // },
  ]

  export let commentData = [
    {
      title:"为1000万用户提供配音服务",
      comment:"第一次试听的时候就惊艳到了，可选的主播种类非常多，带情绪的主播特别适合做电影解说，我从起号开始就选择了搜搜配音，现在粉丝已经90多万了，马上突破百万！",
      name:"李女士",
      avatar: require("@/assets/img/homePage/woman_1.png"),
      nums1:"23",
      nums2:"5.54k",
    },
    {
      title:"完全不像机器配出来的",
      comment:"越来越多的解说朋友都来用搜搜配音，声音超级多又非常真实，完全不像机器配出来的。",
      name:"赵女士",
      avatar: require("@/assets/img/homePage/woman_2.png"),
      nums1:"34",
      nums2:"6.45k",
    },
    {
      title:"有文案提取、去水印很实用的小功能",
      comment:"朋友提起才知道搜搜配音，功能果然很强大，很多细节都能自己自定义调节，解说模式呀，批量处理，还有变声功能，可以应用的场景有很多，基本这一款软件，配音遇到的所有问题就都解决了。",
      name:"刘先生",
      avatar: require("@/assets/img/homePage/man_1.png"),
      nums1:"24",
      nums2:"8.86k",
    },
    {
      title:"每周都有新的声音和风格出来",
      comment:"我是一名老师，为了让同学们更好的理解知识点，会做一些视频课件，搜搜配音的多人配音就很好，可以给角色配音，生成的配音可以像讲故事一样，我的学生都说我做的视频很有意思。",
      name:"吴先生",
      avatar: require("@/assets/img/homePage/man_2.png"),
      nums1:"36",
      nums2:"11.64k",
    },
    {
      title:"上班路上就可以配音特别方便",
      comment:"店里经常会做一些促销广告需要配音，之前一直是找真人配音，配音时间长价格也贵，经销商给我推荐的搜搜配音，声音真的超级真实，完全听不出来是机器合成，关键是机器不满意还能选择真人免费试音。",
      name:"李老师",
      avatar: require("@/assets/img/homePage/man_3.png"),
      nums1:"15",
      nums2:"17.88k",
    },
    {
      title:"搜搜配音更新真的很快",
      comment:"作为一名70后，也紧跟潮流想做短视频，但是普通话不标准，录视频很费时间，直到用了搜搜配音，不仅节约了制作时间，还让我的视频涨粉速度加快。现在也成搜搜配音的铁粉了。",
      name:"杨老板",
      avatar: require("@/assets/img/homePage/man_4.png"),
      nums1:"37",
      nums2:"24.83k",
    },
    {
      title:"在配音界是名列前茅的存在了",
      comment:"非常感谢搜搜配音，在我们需要大力宣传疫情防控⼯作的时候，搜搜配音配音⽼师，⽆条件、⽆报酬的给我们疫情相关的稿件免费配音，⽽且速度快，效率高，音质好。",
      name:"陈主任",
      avatar: require("@/assets/img/homePage/man_5.png"),
      nums1:"46",
      nums2:"26.67k",
    },
    {
      title:"第一批用这个软件配音",
      comment:"搜搜配音的真⼈配音音色非常多，特别适合TVC、宣传片、微电影类型的项目，第⼆、速度快，5分钟就能出试音效果，给到客户选择，客户很满意！",
      name:"周⽼师",
      avatar: require("@/assets/img/homePage/woman_8.png"),
      nums1:"32",
      nums2:"27.86k",
    },
    {
      title:"很多主持人、配音演员都加盟了搜搜",
      comment:"企业经常⽤到的配音有企业宣传、产品促销、企业彩铃、内部培训教程等方面，搜搜配音的近千种音色和⽼师足够满足我们需求，特别是费用实在，没有中间商赚差价。",
      name:"成经理",
      avatar: require("@/assets/img/homePage/man_7.png"),
      nums1:"21",
      nums2:"32.46k",
    },
    {
      title:"长期推荐的一款配音神器",
      comment:"我们通过与搜搜配音的接洽谈判，明码实价的服务，央视播音⽼师能完全符合我们的需求。现在，我们很多出版级别的音频作品都放心交给搜搜配音来制作。",
      name:"⻢总",
      avatar: require("@/assets/img/homePage/man_8.png"),
      nums1:"19",
      nums2:"35.76k",
    },
    {
      title:"界面很好看赏心悦目",
      comment:"轻奢的设计风格、流畅的交互体验。AI智能配音软件和真人配音价格也非常亲⺠，太给力了！希望搜搜配音能坚持⽤声音打动世界的初心，做好搜搜配音。",
      name:"王女⼠",
      avatar: require("@/assets/img/homePage/woman_3.png"),
      nums1:"25",
      nums2:"42.77k",
    },
    {
      title:"我一直以来推荐的配音神器",
      comment:"我非常喜欢搜搜配音的⻆色配音，他们从男、女、童声的模仿到特殊音色的模仿，到音效的制作都非常专业。我们游戏⾥声音选择与搜搜配音独家合作，他们的大客户服务贴心，让我们省心，放心。",
      name:"占⽼师",
      avatar: require("@/assets/img/homePage/man_9.png"),
      nums1:"16",
      nums2:"43.88k",
    },
    {
      title:"大大提升咱们的配音效率",
      comment:"与搜搜配音多次合作，我们的客户常需要标准的美式英语配音，搜搜配音能找到符合我们条件的⽼师。有时还有小语种的服务，日语、俄语、韩语、法语、等十多个语音配音。强大!",
      name:"王先生",
      avatar: require("@/assets/img/homePage/man_10.png"),
      nums1:"33",
      nums2:"48.56k",
    },
    {
      title:"配音就像写文档一样简单方便",
      comment:"搜搜配音是我一直以来推荐的配音神器，简单好用，配音出来又非常真实自然，很多大咖都在用，非常赞！",
      name:"钱总",
      avatar: require("@/assets/img/homePage/man_14.png"),
      nums1:"29",
      nums2:"50.72k",
    },
    {
      title:"配音出来非常像真人",
      comment:"搜搜配音是我看着一步步成长，功能越来越多，越来越好用，大大提升咱们的配音效率，希望能继续进步。",
      name:"孙福明",
      avatar: require("@/assets/img/homePage/man_12.png"),
      nums1:"12",
      nums2:"52.34k",
    },
    {
      title:"自动打轴功能很好用",
      comment:"用了搜搜配音就不想用其他配音软件，就数他方便，配音功能也是丰富多样，配音就像写word文档一样简单方便。",
      name:"李明德",
      avatar: require("@/assets/img/homePage/man_13.png"),
      nums1:"33",
      nums2:"56.85k",
    },
    {
      title:"电脑、手机上都是方便好用",
      comment:"如果你也是做影视解说的，那就快点用搜搜配音吧！配音功能强大，价格实惠，关键配音出来非常像真人，他们还有独家合作的声音。",
      name:"邹丽娜",
      avatar: require("@/assets/img/homePage/woman_4.png"),
      nums1:"42",
      nums2:"58.65k",
    },
    {
      title:"完全不像机器配出来的",
      comment:"搜搜配音的自动打轴功能很好用，配音效果很不错，声音又多，每个都真实自然。",
      name:"明先生",
      avatar: require("@/assets/img/homePage/man_11.png"),
      nums1:"28",
      nums2:"62.77k",
    },
    {
      title:"我的粉丝以为我找了真人配音",
      comment:"我用过很多软件配音，就搜搜配音的简单好用，不管是电脑还是手机上配音都方便的。",
      name:"赵先生",
      avatar: require("@/assets/img/homePage/man_8.png"),
      nums1:"19",
      nums2:"63.45k",
    },
    {
      title:"搜搜配音可以批量配音",
      comment:"越来越多的解说朋友都来用搜搜配音，声音超级多又非常真实，完全不像机器配出来的。",
      name:"郑文锦",
      avatar: require("@/assets/img/homePage/woman_7.png"),
      nums1:"36",
      nums2:"65.28k",
    },
    {
      title:"用搜搜配音配音流量很不错",
      comment:"我用过搜搜配音很多的声音，我的粉丝以为我找了不同真人配音，其实都是搜搜配音配的，很牛！",
      name:"李世海",
      avatar: require("@/assets/img/homePage/man_1.png"),
      nums1:"24",
      nums2:"66.38k",
    },
    {
      title:"成就了搜搜也成就了自己",
      comment:"平时自己配音很麻烦，搜搜配音配音简单，可以批量配音，而且有很多好听的声音，很不错，强力推荐！",
      name:"周静",
      avatar: require("@/assets/img/homePage/woman_5.png"),
      nums1:"26",
      nums2:"68.53k",
    },
    {
      title:"会员群里有很多大咖分享交流",
      comment:"我的很多百万赞的作品都是用搜搜配音配音的，流量很不错，配音效果也特别真实，节奏跟视频很搭。",
      name:"周小艺",
      avatar: require("@/assets/img/homePage/woman_6.png"),
      nums1:"35",
      nums2:"70.32k",
    },
    {
      title:"安安心心用搜搜配音",
      comment:"看着搜搜配音一步步的成长，身为搜搜的老用户，成就了搜搜也成就了自己，向大家强烈推荐！",
      name:"冯云",
      avatar: require("@/assets/img/homePage/man_6.png"),
      nums1:"23",
      nums2:"71.23k",
    },
  ]

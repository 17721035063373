<template>
  <div class="header m1400">
    <div class="header_main flex w1400">
      <img class="logo" src="@/assets/img/homePage/logo.png" alt="" />
      <div class="tab_list flex">
        <div
          v-for="item in tabList"
          :key="item.name"
          class="tab_item"
          :class="{ active_tab_item: $route.fullPath == item.path }"
          @click="go(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <span v-if="!userInfo" class="login" @click="showLoginBox = true"
        >登录</span
      >
      <div
        v-if="!userInfo"
        class="normal_btn free_use"
        @click="showLoginBox = true"
      >
        免费试用
      </div>
      <div v-if="userInfo" class="info_con">
        <img class="avatar" :src="userInfo?.avatar" alt="" />
        <div class="keep_hover">
          <div class="user_panel">
            <div class="panel_head flex">
              <img class="avatar" :src="userInfo?.avatar" alt="" />
              <span class="nickname">{{ userInfo.nickname }}</span>
            </div>
            <div class="panel_main">
              <div
                class="menu_item column"
                v-for="(item, index) in menuList"
                :key="index"
                @click="goCenter(item)"
              >
                <img :src="item.icon" alt="" />
                <span>{{ item.menuName }}</span>
              </div>
            </div>
            <div class="devide_line"></div>
            <div class="panel_foot flex">
              <span @click="logOut">退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoginBox v-if="showLoginBox" @close="showLoginBox = false" />
  </div>
</template>
        
<script>
import LoginBox from "@/components/LoginBox.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: { LoginBox },
  computed: {
    ...mapState(["userInfo", "goFuncPage"]),
  },
  data() {
    return {
      tabList: [
        { name: "首页", path: "/" },
        { name: "AI配音", path: "/AIDubbing" },
        // { name: "真人配音", path: "/artificialDubbing" },
      ],
      showLoginBox: false,
      menuList: [
        { menuName: "我的草稿", icon: require("@/assets/img/draft.png") },
        { menuName: "我的收藏", icon: require("@/assets/img/my_collect.png") },
        { menuName: "我的下载", icon: require("@/assets/img/my_download.png") },
        // { menuName: "", icon: "" },
      ],
    };
  },
  methods: {
    ...mapMutations(["SET_GOFUNCPAGE"]),
    go(item) {
      if (location.hash.includes(item.path)) {
        this.$router.go(0);
        return;
      }
      if (
        !localStorage.getItem("token") &&
        (item.path == "/AIDubbing" || item.path == "/artificialDubbing")
      ) {
        this.showLoginBox = true;
        return;
      }
      this.$router.push(item.path);
    },
    goCenter(item) {
      this.$router.push({ name: "personalCenter", params: item });
    },
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      location.href = location.origin;
    },
  },
  watch: {
    goFuncPage(newVal, oldVal) {
      if (newVal) {
        this.showLoginBox = true;
        this.SET_GOFUNCPAGE(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  height: 64px;
  background: rgba(23, 23, 24, 0.9);
  border-bottom: 1px solid #262627;
  .header_main {
    height: 100%;
    .logo {
      width: 74px;
      height: 22px;
      margin-right: 80px;
    }
    .tab_list {
      .tab_item {
        font-size: 16px;
        color: #717171;
        margin-right: 24px;
        cursor: pointer;
      }
      .active_tab_item {
        color: #ffffff;
      }
    }
    .login {
      font-size: 14px;
      color: #61be74;
      margin-left: auto;
      cursor: pointer;
    }
    .free_use {
      width: 88px;
      height: 32px;
      font-size: 14px;
      margin-left: 24px;
    }
    .info_con {
      margin-left: auto;
      position: relative;
      z-index: 3;
      &:hover {
        .keep_hover {
          display: block;
        }
      }
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
      }
      .keep_hover {
        display: none;
        position: absolute;
        top: 24px;
        left: -230px;
        height: 418px;
        padding-top: 18px;
        .user_panel {
          width: 264px;
          background: #2a2a2b;
          .panel_head {
            width: 264px;
            height: 80px;
            background: url("@/assets/img/works_back.png") no-repeat center /
              264px 80px;
            border-radius: 10px 10px 0 0;
            padding: 0 30px;
            .avatar {
              width: 40px;
              height: 40px;
              cursor: unset;
            }
            .nickname {
              font-weight: 500;
              font-size: 14px;
              color: #dbdbdc;
              margin-left: 12px;
            }
          }
          .panel_main {
            width: 264px;
            height: 270px;

            display: flex;
            flex-wrap: wrap;
            padding: 0 16px;
            .menu_item {
              width: 64px;
              height: 58px;
              margin-top: 24px;
              margin-right: 20px;
              align-items: center;
              cursor: pointer;
              &:nth-of-type(3n) {
                margin-right: 0;
              }
              > img {
                width: 24px;
                height: 24px;
              }
              > span {
                font-size: 14px;
                color: #dbdbdc;
                margin-top: 12px;
              }
            }
          }
          .devide_line {
            width: 232px;
            height: 1px;
            background: #363636;
            margin: 0 auto;
          }
          .panel_foot {
            width: 264px;
            height: 46px;
            border-radius: 0 0 10px 10px;
            padding: 0 16px;
            > span {
              font-size: 14px;
              color: #949495;
              margin-left: auto;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="home m1400">
    <div class="main_guide">
      <div class="main_guide_content column w1400">
        <span class="title">搜搜配音</span>
        <span class="subtitle"
          >支持逐句试听、多音字、停顿、重读、局部变速、多发音人、等近20个调音功能一应俱全，细致打磨每句话
          让配音达到自然流畅境界！多种调音功能助你打造精品配音！</span
        >
        <div class="guide_area">
          <div class="item_con flex">
            <div class="item flex">
              <img src="@/assets/img/homePage/AI_icon.svg" alt="" />
              <div class="des_con column">
                <span class="row_1">智能配音</span>
                <span class="row_2">700+主播随心配</span>
              </div>
              <div class="normal_btn go_dubbing" @click="goAIDubbing">
                去配音
              </div>
            </div>
            <!-- <div class="devide_line"></div>
            <div class="item flex">
              <img src="@/assets/img/homePage/real_icon.svg" alt="" />
              <div class="des_con column">
                <span class="row_1">真人配音</span>
                <span class="row_2">高质量真人配音</span>
              </div>
              <div
                class="normal_btn go_dubbing"
                @click="$router.push('/artificialDubbing')"
              >
                去配音
              </div>
            </div> -->
          </div>
          <div class="check_con flex">
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              专业经验
            </div>
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              双重备案
            </div>
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              引领行业科技化
            </div>
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              24小时全天自动化服务
            </div>
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              可企业级合作
            </div>
            <div class="check_item flex">
              <img src="@/assets/img/homePage/check.svg" alt="" />
              可开发票
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="block_1 column">
      <span class="title"> 搜搜配音 赋能业务 </span>
      <span class="subtitle">累计用户配音超过10000分钟，最低10元/20分钟</span>
      <div class="dubbing_type flex">
        <div
          class="dubbing_item"
          @click="activeDubbingIndex = 0"
          :class="{ active_dubbing_item: 0 == activeDubbingIndex }"
        >
          人工配音
        </div>
        <div
          class="dubbing_item"
          @click="activeDubbingIndex = 1"
          :class="{ active_dubbing_item: 1 == activeDubbingIndex }"
        >
          AI 配音
        </div>
      </div>
      <div class="tab_list flex">
        <div
          class="tab_item"
          @click="activeTabIndex = index"
          :class="{ active_tab_item: index == activeTabIndex }"
          v-for="(item, index) in [1, 1, 1, 1, 1, 1, 1, 1]"
          :key="index"
        >
          配音配音
        </div>
      </div>
    </div> -->
    <div class="block_2">
      <div class="block_2_content column w1400">
        <span class="title">各行业适用</span>
        <div class="classification_list">
          <div
            class="classification_item column"
            v-for="(item, index) in industryList"
            :key="index + 'class'"
          >
            <img
              :src="require(`@/assets/img/homePage/industry_${index + 1}.png`)"
              alt=""
            />
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="block_3" v-if="showBlock3">
      <div class="des_con column w1400">
        <span class="title">万千用户的支持</span>
        <span class="subtitle">百万大V选择 值得信赖</span>
      </div>
      <div class="card_con m1400">
        <div class="scroll_row">
          <div
            class="card_item column"
            v-for="item in commentData.slice(0, 12)"
            :key="item.name"
          >
            <span class="title">{{ item.title }}</span>
            <span class="subtitle">{{ item.comment }}</span>
            <div class="user_con flex">
              <img :src="item.avatar" alt="" />
              <div class="user_con_right column">
                <span class="name">{{ item.name }}</span>
                <div class="nums_con flex">
                  作品<span class="nums">{{ item.nums1 }}</span
                  ><span class="devide_line">|</span>粉丝<span class="nums">{{
                    item.nums2
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="scroll_row" style="padding-left: 200px">
          <div
            class="card_item column"
            v-for="item in commentData.slice(12)"
            :key="item.name"
          >
            <span class="title">{{ item.title }}</span>
            <span class="subtitle">{{ item.comment }}</span>
            <div class="user_con flex">
              <img :src="item.avatar" alt="" />
              <div class="user_con_right column">
                <span class="name">{{ item.name }}</span>
                <div class="nums_con flex">
                  作品<span class="nums">{{ item.nums1 }}</span
                  ><span class="devide_line">|</span>粉丝<span class="nums">{{
                    item.nums2
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block_4 column">
      <span class="title">要配音 就来搜搜</span>
      <div class="normal_btn go_dubbing" @click="goAIDubbing">立即配音</div>
    </div>
  </div>
</template>
      
      <script>
import { commentData } from "@/assets/js/dataList";
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      activeDubbingIndex: 0,
      tabList: ["xxx"],
      activeTabIndex: 0,
      industryList: [
        "影视解说",
        "小说推文",
        "好物推荐",
        "有声读物",
        "授权切片",
        "政务宣传",
        "微课制作",
        "广告促销",
      ],
      commentData: commentData,
      showBlock3: false,
    };
  },
  methods: {
    ...mapMutations(["SET_GOFUNCPAGE"]),
    goAIDubbing() {
      if (!this.userInfo) {
        this.SET_GOFUNCPAGE(true);
      } else {
        this.$router.push("/AIDubbing");
      }
    },
  },
  mounted() {
    //图片有点多有点大 等主体页面加载完之后再渲染
    this.showBlock3 = true;
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #121213;
  .main_guide {
    width: 100%;
    height: 604px;
    background: url("@/assets/img/homePage/main_guide_back.png") no-repeat
      center / 100% 604px;
    .main_guide_content {
      .title {
        font-weight: 600;
        font-size: 58px;
        color: #ffffff;
        margin-top: 80px;
      }
      .subtitle {
        width: 756px;
        font-weight: 300;
        font-size: 16px;
        color: #ffffff;
        line-height: 32px;
        margin-top: 20px;
      }
      .guide_area {
        width: 1400px;
        height: 230px;
        margin-top: 48px;
        padding: 0 32px;
        border: 2px solid rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.1); /* 半透明背景 */
        backdrop-filter: blur(10px); /* 背景模糊 */
        -webkit-backdrop-filter: blur(10px); /* 兼容老版本 Safari */
      }
      .item_con {
        height: 138px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        justify-content: center;

        .item {
          width: 636px;
          > img {
            width: 82px;
            height: 82px;
          }
          .des_con {
            margin-left: 20px;
            .row_1 {
              font-weight: 600;
              font-size: 20px;
              color: #ffffff;
            }
            .row_2 {
              font-weight: 300;
              font-size: 16px;
              color: #ffffff;
              margin-top: 4px;
            }
          }
          .go_dubbing {
            width: 124px;
            height: 48px;
            font-size: 20px;
            margin-left: auto;
          }
        }
        .devide_line {
          width: 1px;
          height: 82px;
          background: rgba(255, 255, 255, 0.1);
          margin: 0 32px;
        }
      }
      .check_con {
        height: 92px;
        width: 100%;
        justify-content: space-between;
        .check_item {
          font-size: 16px;
          color: #dbdbdc;
          > img {
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  .block_1 {
    width: 100%;
    height: 893px;
    background: url("@/assets/img/homePage/block_1_back.png") no-repeat right /
      750px 893px;
    align-items: center;
    position: relative;
    .title {
      font-weight: 600;
      font-size: 52px;
      color: #ffffff;
      margin-top: 80px;
    }
    .subtitle {
      font-weight: 300;
      font-size: 14px;
      color: #ffffff;
      margin-top: 8px;
    }
    .dubbing_type {
      width: 248px;
      height: 48px;
      background: #171718;
      border-radius: 8px;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 152px;
      .dubbing_item {
        width: 120px;
        height: 40px;
        background: #171718;
        border-radius: 8px;
        font-size: 14px;
        color: #949495;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
      }
      .active_dubbing_item {
        color: #ffffff;
        background: #1e1e1f;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
      }
    }
    .tab_list {
      border-bottom: 1px solid #262627;
      position: absolute;
      top: 303px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      justify-content: center;
      .tab_item {
        width: 140px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        font-size: 14px;
        color: #949495;
        cursor: pointer;
      }
      .active_tab_item {
        color: #61be74;
        border-bottom: 2px solid #61be74;
      }
    }
  }
  .block_2 {
    width: 100%;
    height: 685px;
    background: url("@/assets/img/homePage/block_2_back.png") no-repeat center /
      100% 685px;
    .block_2_content {
      .title {
        font-weight: 600;
        font-size: 52px;
        color: #ffffff;
        margin: 80px 0;
      }
      .classification_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .classification_item {
          width: 335px;
          height: 176px;
          background: #1d201e;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          justify-content: center;
          margin-bottom: 20px;
          align-items: center;
          font-weight: 600;
          font-size: 20px;
          color: #ffffff;
          > img {
            width: 60px;
            height: 60px;
            margin-bottom: 24px;
          }
          cursor: pointer;
          &:hover {
            background: url("@/assets/img/homePage/hover_back.png") no-repeat
              center / 100% 100%;
          }
        }
      }
    }
  }
  .block_3 {
    padding-bottom: 60px;
    .des_con {
      .title {
        height: 73px;
        font-weight: 600;
        font-size: 52px;
        color: #ffffff;
        line-height: 73px;
        margin-top: 80px;
      }
      .subtitle {
        height: 22px;
        font-weight: 300;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        margin-top: 8px;
        margin-bottom: 48px;
      }
    }
    .card_con {
      overflow: hidden;
      &:hover {
        .scroll_row {
          animation-play-state: paused;
        }
      }
      .scroll_row {
        display: flex;
        animation: marquee 8s linear infinite;
        margin-bottom: 20px;
        @keyframes marquee {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        .card_item {
          width: 400px;
          height: 224px;
          background: #1e1e1f;
          border-radius: 8px;
          margin-right: 20px;
          color: #ffffff;
          font-size: 32px;
          flex-shrink: 0;
          padding: 20px;
          .title {
            font-weight: 600;
            font-size: 20px;
            color: #ffffff;
          }
          .subtitle {
            font-weight: 300;
            font-size: 14px;
            color: #ffffff;
            margin-top: 12px;
            line-height: 22px;
            padding-right: 10px;
          }
          .user_con {
            margin-top: auto;
            > img {
              width: 64px;
              height: 64px;
              border-radius: 50%;
              border: 3px solid #2a2a2b;
            }
            .user_con_right {
              margin-left: 16px;
              .name {
                font-weight: 600;
                font-size: 18px;
                color: #dbdbdc;
              }
              .nums_con {
                font-size: 12px;
                color: #949495;
                margin-top: 6px;
                .nums {
                  font-size: 12px;
                  color: #dbdbdc;
                  margin-left: 4px;
                }
                .devide_line {
                  color: #2a2a2b;
                  margin: 0 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  .block_4 {
    width: 100%;
    height: 320px;
    background: url("@/assets/img/homePage/block_4_back.png") no-repeat center /
      100% 320px;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 52px;
      color: #ffffff;
      margin-top: 80px;
    }
    .go_dubbing {
      width: 144px;
      height: 48px;
      font-size: 20px;
      margin-top: 40px;
    }
  }
}
</style>
<template>
  <div class="login_con" @click="$emit('close')">
    <div class="main" @click.stop>
      <div class="head"></div>
      <div class="main_area">
        <div ref="wxQr" id="wxQr" class="code_con"></div>
        <div class="code_tips">微信扫码登录</div>
      </div>
    </div>
  </div>
</template>
      
    <script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      wxQrApi: null,
    };
  },
  methods: {
    ...mapMutations(["SET_USERINFO"]),
  },
  mounted() {
    sessionStorage.setItem("href", location.href);

    const script = document.createElement("script");
    script.src =
      "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    script.onload = () => {
      console.log("外部脚本加载完成");
      !(function (a, b, c) {
        function d(a) {
          var c = "default";
          a.self_redirect === !0
            ? (c = "true")
            : a.self_redirect === !1 && (c = "false");
          var d = b.createElement("iframe"),
            e =
              "https://open.weixin.qq.com/connect/qrconnect?appid=" +
              a.appid +
              "&scope=" +
              a.scope +
              "&redirect_uri=" +
              a.redirect_uri +
              "&state=" +
              a.state +
              "&login_type=jssdk&self_redirect=" +
              c +
              "&styletype=" +
              (a.styletype || "") +
              "&sizetype=" +
              (a.sizetype || "") +
              "&bgcolor=" +
              (a.bgcolor || "") +
              "&rst=" +
              (a.rst || "");
          (e += a.style ? "&style=" + a.style : ""),
            (e += a.href ? "&href=" + a.href : ""),
            (d.src = e),
            (d.frameBorder = "0"),
            (d.allowTransparency = "true"),
            (d.sandbox =
              "allow-scripts allow-top-navigation allow-same-origin"), //重点
            (d.scrolling = "no"),
            (d.width = "300px"),
            (d.height = "400px");
          var f = b.getElementById(a.id);
          (f.innerHTML = ""), f.appendChild(d);
        }
        a.WxLogin = d;
      })(window, document);
      let myCss =
        "data:text/css;base64,Ym9keSB7DQogICAgcGFkZGluZzogMCAhaW1wb3J0YW50Ow0KICAgIG92ZXJmbG93OiBoaWRkZW4gIWltcG9ydGFudDsNCiAgfQ0KICAudGl0bGUgew0KICAgIGRpc3BsYXk6IG5vbmUgIWltcG9ydGFudDsNCiAgfQ0KICAuaW5mbyB7DQogICAgZGlzcGxheTogbm9uZSAhaW1wb3J0YW50Ow0KICB9DQogIC53cnBfY29kZSB7DQogICAgd2lkdGg6IDEyMHB4ICFpbXBvcnRhbnQ7DQogICAgaGVpZ2h0OiAxMjBweCAhaW1wb3J0YW50Ow0KICB9DQogIC5pbXBvd2VyQm94IC5xcmNvZGUgew0KICAgIHdpZHRoOiAxMjBweCAhaW1wb3J0YW50Ow0KICAgIGhlaWdodDogMTIwcHggIWltcG9ydGFudDsNCiAgICBib3JkZXItcmFkaXVzOiA0cHggIWltcG9ydGFudDsNCiAgICBib3JkZXI6IG5vbmUgIWltcG9ydGFudDsNCiAgICBtYXJnaW4tdG9wOiAwcHg7DQogIH0=";
      var obj = new WxLogin({
        self_redirect: false,
        id: "wxQr",
        appid: "wx0f0706bd411ae2e2",
        scope: "snsapi_login",
        redirect_uri: encodeURI("http://sousou.zaiguwang.com"),
        state: "",
        style: "",
        href: myCss,
      });
    };
    document.head.appendChild(script);
  },
};
</script>
  
    <style lang="scss" scoped>
.login_con {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;

  .main {
    background: #2a2a2b;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    .head {
      width: 264px;
      height: 80px;
      background: url("@/assets/img/popup_back.png") no-repeat center / 264px
        80px;
      border-radius: 8px 8px 0 0;
    }
    .main_area {
      width: 264px;
      height: 234px;
      .code_con {
        width: 120px;
        height: 120px;
        background: #1d201e;
        border-radius: 4px;
        margin-top: 24px;
        margin-left: 72px;
      }
      .code_tips {
        font-size: 14px;
        color: #dbdbdc;
        margin-top: 24px;
        text-align: center;
      }
    }
  }
}
</style>
      